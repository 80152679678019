<template>
  <div>

    <div class="col-12">
      <div class="card mb-4">
        <div class="card-header">
          <h4 class="card-title">
            <span>{{RelName}}</span>
          </h4>
     
          <b-col v-if="showFiltro ? false : true ">
            <div style="float:right">
              <div class="DivBotaoLadoLado" >
              <b-button variant="danger" @click="$router.push('../Relatorios')">
                   <span>RETORNAR</span>
               </b-button>
              </div>
               
               <div class="DivBotaoLadoLado" >
               <b-button v-b-toggle="'collapse-filtro'" variant="primary" >
                 <span>FILTROS</span>
               </b-button>
               </div>
            </div>
          </b-col>

        </div>

        <b-collapse visible  id="collapse-filtro">
        <div class="card-body">
          
          <b-row>

            <b-col md="6" xl="3" class="mb-1">
              <b-form-group
            label="Data Inicial:"
            >
            <b-form-datepicker
                 id="PeridoIni"
                 locale="pr-BR"
                 :date-format-options="{
                   year: 'numeric',
                   month: 'numeric',
                   day: 'numeric',
                 }"
                 today-button
                 reset-button
                 v-model="PeridoIni"
                 placeholder="Selecione"
                 :required="true"
               />
                  </b-form-group>
            </b-col>

            <b-col md="6" xl="3" class="mb-1">
              <b-form-group
            label="Data Final:"
            >
            <b-form-datepicker
                 id="PeridoFin"
                 locale="pr-BR"
                 :date-format-options="{
                   year: 'numeric',
                   month: 'numeric',
                   day: 'numeric',
                 }"
                 today-button
                 reset-button
                 v-model="PeridoFin"
                 placeholder="Selecione"
                 :required="true"
               />
                  </b-form-group>
            </b-col>


            <b-col md="6" xl="3" class="mb-1">
              <b-form-group label="Volume:" >
                <multiselect
                    v-model="CodVol"
                    placeholder="Selecione"
                    label="name"
                    track-by="code"
                    :options="CodVolOption"
                    :multiple="false"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  >
                  </multiselect>

              </b-form-group>
            </b-col>

     
 
          </b-row>



          <b-row>

            <b-col md="6" xl="6" class="mb-1">
              <b-form-group label="Cidade:" >
                <multiselect
                    v-model="CodCid"
                    placeholder="Selecione"
                    label="name"
                    track-by="code"
                    :options="CodCidOption"
                    :multiple="true"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  >
                  </multiselect>

              </b-form-group>
            </b-col>


            <b-col md="6" xl="3" class="mb-1">
              <b-form-group label="UF:" >
                <multiselect
                    v-model="UF"
                    placeholder="Selecione"
                    label="name"
                    track-by="code"
                    :options="UFOption"
                    :multiple="true"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  >
                  </multiselect>

              </b-form-group>
            </b-col>


          </b-row>


          <b-row>
            
            <b-col md="6" xl="5" class="mb-1">
             <b-form-group label="Produto:" >

                 <multiselect
                   v-model="CodProd"
                   placeholder="Selecione"
                   label="name"
                   track-by="code"
                   :options="CodProdOption"
                   :multiple="true"
                   :taggable="false"
                   :close-on-select="true"
                   :option-height="50"
                   :show-labels="false"
                 ></multiselect>

             </b-form-group>
           </b-col>

           <b-col md="6" xl="4" class="mb-1">
             <b-form-group label="Grupo:" >

                 <multiselect
                   v-model="CodGruProd"
                   placeholder="Selecione"
                   label="name"
                   track-by="code"
                   :options="CodGruProdOption"
                   :multiple="true"
                   :taggable="false"
                   :close-on-select="true"
                   :option-height="50"
                   :show-labels="false"
                 ></multiselect>

             </b-form-group>
           </b-col>

          </b-row>




        <b-row>
            


        <b-col
                md="6"
                xl="12"
                class="mb-1"
              >
              <div class="DivBotaoLadoLado" >
               <b-button
              variant="danger"
              type="button"
              @click="$router.push('../Relatorios')"
            >
              <span>RETORNAR</span>
            </b-button>
              </div>

              <div class="DivBotaoLadoLado" >
            <b-button v-b-toggle="'collapse-filtro'"
              variant="primary"
              type="submit"
              @click="ConsultarRelatorio"
            >
              <span>CONSULTAR</span>
            </b-button>
              </div>
              
          </b-col>


         </b-row>
         
        </div>
        </b-collapse>
        
      </div>
    </div>


      
    <div class="col-12" v-if="TabRelVendaProdutoQualidadePosition >= 0">
            <div class="card mb-4">
              <div class="card-body pb-60" >
                
                
              <div v-if="TabRelVendaProdutoQualidadePosition == 0">
                
                <div id="TabRelVendaProdutoQualidade">

                <h4 class="fw-bolder mb-1">Detalhe</h4>  
                 <b-table
                  striped
                  hover
                  responsive
                  small
                  sticky-header
                  :items="TabRelVendaProdutoQualidade"
                  :fields="visibleFields"
                  table-class = "font-size11"
                  thead-class = "font-size11"
                  thead-tr-class = "font-size11"
                  
                  
                >

                 <template #cell(QTD)="row">
                      {{ TextFormat(row.value,'money') }}
                 </template>

                             


                </b-table>
                </div>

                <div class="col-2" style="float:right">
                   <b-form-group
                      label="">
                      <b-form-select
                      v-model="exportarParaSelected"
                      :options="exportarParaOptions"
                      @change="ExportarDoc('TabRelVendaProdutoQualidade','')"
                      >
                      </b-form-select>
                   </b-form-group>
                </div>

                
              </div>
               

             </div>
            </div>
    </div>



  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import VueApexCharts from 'vue-apexcharts'
import 'bootstrap-icons/font/bootstrap-icons.css'
import apiPortalSky from '@/services/apiPortalSky'
import AlertaMsg from '@/customPortal/AlertaMsg.vue'
import ConexaoAPI from '@/customPortal/ConexaoAPI.vue'
import Spinner from '@/components/Spinner.vue'
import UtilVUE from '@/customPortal/UtilVUE.vue'

import {
  BFormGroup,
  BRow,
  BCol,
  BButton,
  BTable,
  BFormSelect,
  BFormInput,
  BCollapse,
  BCard,
  VBToggle,
  BFormDatepicker,
} from 'bootstrap-vue'

export default {
  components: {
    VueApexCharts,
    Multiselect,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BTable,
    BFormSelect,
    BFormInput,
    BCollapse,
    BCard,
    BFormDatepicker,
  },
  directives: { 'b-toggle': VBToggle },
  data() {
    return {
      showFiltro: true,
      FiltroRelatorio: [],
      RelName: '',
      RelRota: '',
      TipoConsulta: '',
      TabRelVendaProdutoQualidade: [],
      TabRelVendaProdutoQualidadefields: [],
      PeridoIni: '',
      PeridoFin: '',
      CodProd: [],
      CodProdOption: [],
      CodGruProd: [],
      CodGruProdOption: [],
      exportarParaSelected: 'Exporte',
      exportarParaOptions: [
        { value: 'Exporte', text: 'Exporte' },
        { value: 'XLSX', text: 'Excel' },
      ],
      TabRelVendaProdutoQualidadePosition: -1,
      CodVol: { "name": "KG", "code": "KG"} ,
      CodVolOption: [],
      CodVolSel: '',
      CodCid: [] ,
      CodCidOption: [],
      UF: [] ,
      UFOption: [],
    }
  },
  methods: {
    async MostraReport() {
      let notification = ""
      Spinner.StarLoad()
      const usuario = this.$session.get('usuario')
      
      let ParametroJson = ""
      let URLpost = `/Report/MostraReport?CODUSU=${usuario.codusu}&ROTA=${this.RelRota}`
      await apiPortalSky.post(URLpost, ParametroJson).then(response => {
        if (response.status === 200) {
          if (response.data != null) {
            this.RelName = response.data.descricaoRelatorio
          } else {
            notification = ConexaoAPI.MsgFiltroSemRetorno()
            AlertaMsg.FormataMsg(notification)
          }
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
      })

      if(notification === '') {
       Spinner.EndLoad()
      }

    },
    async ConsultarOpcoes() {
      let notification = ""
      Spinner.StarLoad()
      const usuario = this.$session.get('usuario')

      if(notification === '') {
       
       let ParametroJson = ""
       let URLpost = `/Opcoes/ConsultaOpcoes?CODUSU=${usuario.codusu}&OPCAO=ProdutoEstr&ATIVO=S`
       await apiPortalSky.post(URLpost, ParametroJson).then(response => {
         if (response.status === 200) {
           if (response.data.length > 0) {
             this.CodProdOption = response.data
           } else {
             notification = ConexaoAPI.MsgFiltroSemRetorno()
             AlertaMsg.FormataMsg(notification)
           }
         }
       }).catch(error => {
         notification = ConexaoAPI.ValidaErro(error)
         AlertaMsg.FormataMsg(notification)
       })
       
       }

       if(notification === '') {
       
       let ParametroJson = ""
       let URLpost = `/Opcoes/ConsultaOpcoes?CODUSU=${usuario.codusu}&OPCAO=GrupoProdutoV2&ATIVO=S`
       await apiPortalSky.post(URLpost, ParametroJson).then(response => {
         if (response.status === 200) {
           if (response.data.length > 0) {
             this.CodGruProdOption = response.data
           } else {
             notification = ConexaoAPI.MsgFiltroSemRetorno()
             AlertaMsg.FormataMsg(notification)
           }
         }
       }).catch(error => {
         notification = ConexaoAPI.ValidaErro(error)
         AlertaMsg.FormataMsg(notification)
       })
       
       }

       if(notification === '') {
       
       let ParametroJson = ""
       let URLpost = `/Opcoes/ConsultaOpcoes?CODUSU=${usuario.codusu}&OPCAO=UnidadeMedida`
       await apiPortalSky.post(URLpost, ParametroJson).then(response => {
         if (response.status === 200) {
           if (response.data.length > 0) {
             this.CodVolOption = response.data
           } else {
             notification = ConexaoAPI.MsgFiltroSemRetorno()
             AlertaMsg.FormataMsg(notification)
           }
         }
       }).catch(error => {
         notification = ConexaoAPI.ValidaErro(error)
         AlertaMsg.FormataMsg(notification)
       })
       
       }


       if(notification === '') {
       
       let ParametroJson = ""
       let URLpost = `/Opcoes/ConsultaOpcoes?CODUSU=${usuario.codusu}&OPCAO=Cidade`
       await apiPortalSky.post(URLpost, ParametroJson).then(response => {
         if (response.status === 200) {
           if (response.data.length > 0) {
             this.CodCidOption = response.data
           } else {
             notification = ConexaoAPI.MsgFiltroSemRetorno()
             AlertaMsg.FormataMsg(notification)
           }
         }
       }).catch(error => {
         notification = ConexaoAPI.ValidaErro(error)
         AlertaMsg.FormataMsg(notification)
       })
       
       }

       if(notification === '') {
       
       let ParametroJson = ""
       let URLpost = `/Opcoes/ConsultaOpcoes?CODUSU=${usuario.codusu}&OPCAO=UF`
       await apiPortalSky.post(URLpost, ParametroJson).then(response => {
         if (response.status === 200) {
           if (response.data.length > 0) {
             this.UFOption = response.data
           } else {
             notification = ConexaoAPI.MsgFiltroSemRetorno()
             AlertaMsg.FormataMsg(notification)
           }
         }
       }).catch(error => {
         notification = ConexaoAPI.ValidaErro(error)
         AlertaMsg.FormataMsg(notification)
       })
       
       }



      if(notification === '') {
       Spinner.EndLoad()
      }

    },
    async ConsultarRelatorio(OrigemConsulta) {
      Spinner.StarLoad()

      //Inicio Dados Base Relatorio
      this.showFiltro = false
      const usuario = this.$session.get('usuario')
      let notification = ""
      this.FiltroRelatorio = []
      //Fim Dados Base Relatorio

      //Inicio Limpa Dados Retorno
      this.TabRelVendaProdutoQualidade = []
      
      // Fim Limpa Dados Retorno
      
      //Inicio Volta Todos Niveis para posição inicial
      this.TabRelVendaProdutoQualidadePosition = 0
      //Fim Volta Todos Niveis para posição inicial


      //Inicio Pega parametros de Parametros
       if(this.PeridoIni != "")
       {
         const Filtro = {
           parametro: 'DTI',
           valor: this.PeridoIni,
         }
         this.FiltroRelatorio.push(Filtro)
       }

       if(this.PeridoFin != "")
       {
         const Filtro = {
           parametro: 'DTF',
           valor: this.PeridoFin,
         }
         this.FiltroRelatorio.push(Filtro)
       }

   
       this.CodProd.forEach(Item => {
        const Filtro = {
          parametro: 'CODPROD',
          valor: Item.code,
        }
        this.FiltroRelatorio.push(Filtro)
      })

      this.CodGruProd.forEach(Item => {
        const Filtro = {
          parametro: 'CODGRUPOPROD',
          valor: Item.code,
        }
        this.FiltroRelatorio.push(Filtro)
      })


      this.UF.forEach(Item => {
        const Filtro = {
          parametro: 'UF',
          valor: Item.code,
        }
        this.FiltroRelatorio.push(Filtro)
      })


      this.CodCid.forEach(Item => {
        const Filtro = {
          parametro: 'CODCID',
          valor: Item.code,
        }
        this.FiltroRelatorio.push(Filtro)
      })

      if(this.CodVol != null){
        if(Array.isArray(this.CodVol) == false)
        {
          const Filtro = {
            parametro: 'CODVOL',
            valor: this.CodVol.code,
          }
          this.FiltroRelatorio.push(Filtro)
        }
      }


      //Fim Pega parametros de Parametros
      

      let ParametroJson = ''
      let URLpost = ''
      

    if(notification === '') {
      ParametroJson = JSON.parse(JSON.stringify(this.FiltroRelatorio))
      URLpost = `/Report/ExecutaRelVendaProduto?CODUSU=${usuario.codusu}&Nivel=RelVendaProdutoQualidade&TypeRDLCexport=`
      await apiPortalSky.post(URLpost, ParametroJson).then(response => {
        if (response.status === 200) {
          if (response.data.length > 0) {
            this.TabRelVendaProdutoQualidade = response.data

            this.CustomFields()
             
           
            
          } else {
            notification = ConexaoAPI.MsgFiltroSemRetorno()
            AlertaMsg.FormataMsg(notification)
            
          }
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
        
      })

    }



     if(notification === '') {
       Spinner.EndLoad()
     }

    },
    TabRelVendaProdutoQualidadeTotal(Coluna) {
      let Valor  = 0

      switch (Coluna) {
        case 'VLRNOTA':
          Valor = this.TabRelAcompanhamentoVerba.reduce((accum, TabRelAcompanhamentoVerba) => { return accum + TabRelAcompanhamentoVerba.VLRNOTA }, 0.0)
        break

             
      }
      
      if(!isNaN(Valor))
      {
        return Valor
      }
      else{
        return 0
      }
      
    },
    CustomFields() {
      

      this.CodVolSel = this.TabRelVendaProdutoQualidade[0].CODVOL
     
      this.TabRelVendaProdutoQualidadefields = [
       { key: 'DATA_EMISSAO', label: 'Data Emissão', visible: true,  sortable: true,tdClass: 'td100' },
       /*{ key: 'CODPROD', label: 'Cod.Sankya', visible: true,  sortable: true,tdClass: 'td100' },
       { key: 'REFERENCIA', label: 'Cod.Alternativo', visible: true, sortable: true,tdClass: 'td100' },*/
       { key: 'AD_CODESTRAPOLO', label: 'Cod.Estruturado', visible: true, sortable: true,tdClass: 'td100' },
       { key: 'DESCRPROD', label: 'Descrição', visible: true, sortable: true,tdClass: 'td300' }, 
       { key: 'CIDADE', label: 'CIDADE', visible: true,sortable: true,tdClass: 'td100' },
       { key: 'UF', label: 'UF', visible: true,sortable: true,tdClass: 'td100' },
       { key: 'QTD', label: this.CodVolSel, visible: true, sortable: false,tdClass: 'td100' },
       { key: 'DESCRGRUPOPROD', label: 'Grupo', visible: true, sortable: true,tdClass: 'td200' }, 
              
      ]


      
      
    },
    TextFormat(valor, tipo) {
      return UtilVUE.TextFormat(valor, tipo)
    },
    ExportarDoc (ElementToExport,DocName) {
      let DataExport = ""
      switch (ElementToExport) {
                case 'TabRelVendaProdutoQualidade':
                DataExport = this.TabRelVendaProdutoQualidade
                DocName = 'RelVendaProdutoQualidade'
                break
      }    
      UtilVUE.ExportDoc(this.exportarParaSelected,DocName,ElementToExport,DataExport)
      this.exportarParaSelected = 'Exporte'
      return

    },
  },
  computed: {
    visibleFields() {
        return this.TabRelVendaProdutoQualidadefields.filter(field => field.visible)
      }
  },
  mounted() {
    if (this.$route.name != null) {
      this.RelRota = this.$route.name
      this.PeridoIni = new Date()
      this.PeridoFin = new Date()
      this.MostraReport()
      this.ConsultarOpcoes()
    }
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
